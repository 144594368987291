<template>
  <div class="ripple">
    <img src="img/medicusQ.png" class="img-responsive fit-image" />
  </div>
</template>

<script setup></script>

<style scoped>
.fit-image {
  max-height: 100px;
}
.ripple {
  position: relative;
  display: flex;
  left: 15px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
}

.ripple::after {
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: #142b58;
  border-radius: 100%;
  -webkit-animation-name: ripple;
  animation-name: ripple;
  -webkit-animation-duration: 1.2s;
  animation-duration: 1.2s;
  -webkit-animation-delay: 7.5s;
  animation-delay: 7.58s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
  animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
  z-index: -1;
}

@-webkit-keyframes ripple {
  from {
    opacity: 1;
    transform: scale3d(0.75, 0.75, 1);
  }
  to {
    opacity: 0;
    transform: scale3d(2, 2, 1);
  }
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale3d(0.75, 0.75, 1);
  }
  to {
    opacity: 0;
    transform: scale3d(2, 2, 1);
  }
}
</style>
