export const riskQuestions = [
    {
        "type": "bool",
        "text": "Wurde bei Ihnen eine Gefäßerkrankung am Herzen mit CT oder Herzkatheter festgestellt?",
        "fieldName": "angiopathyConfirmed",
        "medicusMessage": "Gefäßerkrankungen wie Verengungen oder Verkalkungen der Herzkranzgefäße werden meist durch eine CT-Untersuchung (eine spezielle Röntgenaufnahme) oder durch eine Herzkatheteruntersuchung ermittelt.",
    },
    // {
    //     "type": "boolUncertainty",
    //     "text": "Wurde bei Ihnen eine andere Gefäßerkrankung fesgestellt?",
    //     "fieldName": "angiopathy",
    //     "medicusMessage": "Platzhalter",
    // },
    {
        "type": "bool",
        "text": "Haben Sie einen Herzschrittmacher?",
        "fieldName": "pacemaker",
        "medicusMessage": "Herzschrittmacher oder Defibrillatoren sind kleine Geräte, die unter die Haut, meist im Brustbereich, implantiert werden und das Herz unterstützen, im richtigen Rhythmus zu schlagen. Diese Geräte werden im Zuge einer Operation eingesetzt.",
    },
    {
        "type": "bool",
        "text": "Haben Sie Diabetes oder werden deshalb behandelt?",
        "fieldName": "diabetes",
        "medicusMessage": "Diabetes, umgangssprachlich „Zuckerkrankheit“, ist eine Erkrankung, die durch dauerhaft hohe Blutzuckerwerte gekennzeichnet ist. Oft ist es notwendig, mehrmals am Tag die Blutzuckerwerte zu überprüfen und sich Insulin zu spritzen.",
    },
    {
        "type": "bool",
        "text": "Hatten Sie schon einen Herzinfarkt?",
        "fieldName": "myocardialInfarction",
        "medicusMessage": "Ein Herzinfarkt, entsteht durch eine Unterversorgung des Herzmuskels mit Sauerstoff, meist aufgrund einer Gefäßverengung im Herzen. Der Herzinfarkt erfordert eine akute ärztliche Behandlung.",
    },
    {
        "type": "bool",
        "text": "Haben Sie Bluthochdruck oder werden deshalb behandelt?",
        "fieldName": "hypertension",
        "medicusMessage": "Bluthochdruck, auch Hypertonie genannt, ist eine häufige Erkrankung, die durch dauerhaft erhöhte Blutdruckwerte gekennzeichnet ist.",
    },
    {
        "type": "bool",
        "text": "Leiden Sie an Herzschwäche oder werden deshalb behandelt?",
        "fieldName": "heartFailure",
        "medicusMessage": "Herzschwäche, auch Herzinsuffizienz genannt, ist eine Erkrankung, bei der das Herz nicht genug Pumpleistung erbringt, um den Körper optimal zu versorgen.",
    },
    {
        "type": "bool",
        "text": "Hatten Sie schon einen Schlaganfall?",
        "fieldName": "stroke",
        "medicusMessage": "Ein Schlaganfall entsteht durch eine unzureichende Blutversorgung des Gehirns, entweder durch ein blockiertes oder ein eingerissenes Blutgefäß. Der Schlaganfall erfordert eine akute medizinische Versorgung.",
    },
    {
        "type": "bool",
        "text": "Haben oder hatten Sie eine Krebserkrankung?",
        "fieldName": "cancer",
        "medicusMessage": "Krebserkrankungen, auch Karzinome genannt, entstehen durch die Vermehrung bösartiger Zellen im Körper und können in jedem Organ auftreten. Häufige Krebsformen sind Brust-, Prostata-, Darm- und Lungenkrebs.",
    },
    // {
    //     "type": "multipleChoiceMedication",
    //     "text": "Nehmen Sie die folgenden Medikamente?",
    //     "fieldName": "medications",
    //     "medicusMessage": "",
    // },
    {
        "type": "boolExtended",
        "text": "Rauchen Sie?",
        "extendedText": "Wie viele Zigaretten rauchen Sie <b>pro Tag </b>?",
        "extendedLabels": ["<10", "10-20", "20-40", ">40"],
        "extendedValues": ["<10", "10-20", "20-40", ">40"],
        "orientation": "horizontal",
        "fieldName": "smoking",
        "medicusMessage": "Das Rauchen von Zigaretten erhöht das Risiko für Herz-Kreislauf-Erkrankungen erheblich.",
    },
    // { old alcohol question
    //     "type": "boolExtended",
    //     "text": "Trinken Sie regelmäßig Alkohol?",
    //     "extendedText": "Wie viele alkoholische Getränke trinken Sie <b>pro Tag</b>?",
    //     "extendedLabels": ["<2", "2-4", "4-6", ">6"],
    //     "extendedValues": ["<2", "2-4", "4-6", ">6"],
    //     "fieldName": "alcohol",
    //     "medicusMessage": "Platzhalter",
    // },
    {
        "type": "boolExtended",
        "text": "Trinken Sie gelegentlich Alkohol?",
        "extendedText": "Wie oft trinken Sie Alkohol?",
        "extendedLabels": ["1 Mal im Monat", "2-4 Mal im Monat", "3-4 Mal in der Woche", "Öfter als 4 Mal in der Woche"],
        "extendedValues": ["1/Monat", "2-4/Monat", "3-4 Mal/Woche", ">4/Woche"],
        "orientation": "vertical",
        "fieldName": "alcohol",
        "medicusMessage": "Regelmäßiger Alkoholkonsum gilt als Risikofaktor für Herz-Kreislauf-Erkrankungen. Wird an mehr als fünf Tagen pro Woche Alkohol konsumiert, spricht man von regelmäßigem Konsum.",
    },
    // { old activity question
    //     "type": "boolExtended",
    //     "text": "Machen Sie regelmäßig Sport oder gehen Sie spazieren?",
    //     "extendedText": "Wie oft betätigen Sie sich körperlich <b>in der Woche</b>?",
    //     "extendedLabels": ["<2", "2-4", "4-6", ">6"],
    //     "extendedValues": ["<2", "2-4", "4-6", ">6"],
    //     "orientation": "horizontal",
    //     "fieldName": "regularlyActive",
    //     "medicusMessage": "Sport reduziert das Risiko, an Herz-Kreislauf-Erkrankungen zu erkranken. Auch körperliche Aktivitäten mit geringer Intensität wirken sich bereits positiv auf die Gesundheit aus.",
    // },
]
