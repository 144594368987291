<template>
  <transition>
    <section v-if="store.step === 2">
      <EligibilityMedicusIntroScreen />
      <NextButton />
    </section>
  </transition>

  <transition>
    <section v-if="store.step === 3">
      <EligibilityStartScreen />
    </section>
  </transition>

  <!-- <transition>
    <section v-if="store.step === 4">
      <EligibilityAgeScreenStart />
      <NextButton />
    </section>
  </transition> -->

  <transition>
    <section v-if="store.step === 4">
      <EligibilityAgeScreen />
    </section>
  </transition>

  <transition>
    <section v-if="store.step === 5">
      <EligibilityAfibScreen />
    </section>
  </transition>

  <transition>
    <section v-if="store.step === 6">
      <EligibilityAnticoagulantScreen />
    </section>
  </transition>
</template>

<script setup>
// import EligibilityAgeScreenStart from "@/components/screens/eligibility/decrep_EligibilityAgeScreenStart.vue";
import EligibilityAgeScreen from "@/components/screens/eligibility/EligibilityAgeScreen.vue";
import EligibilityStartScreen from "@/components/screens/eligibility/EligibilityStartScreen.vue";
import EligibilityAnticoagulantScreen from "@/components/screens/eligibility/EligibilityAnticoagulantScreen.vue";
import EligibilityAfibScreen from "@/components/screens/eligibility/EligibilityAfibScreen.vue";
import EligibilityMedicusIntroScreen from "@/components/screens/eligibility/EligibilityMedicusIntroScreen.vue";
import NextButton from "@/components/navigation/NextButton.vue";
import { dhRegistrationStore } from "@/stores/dh-registration";
const store = dhRegistrationStore();
</script>

<style scoped></style>
