<template>
  <h2>Aufklärungsvideo</h2>
  <div class="row justify-content-center">
    <video
      ref="video"
      controls
      class="my-4 vert_video"
      preload="none"
      @play="enterFullscreenOnce"
      poster="../../../../public/img/infoVid_thumbnail.png"
      playsinline
    >
      <source src="../../../../public/vid/infoVid_v0.mp4" type="video/mp4" />
    </video>
  </div>
  <div class="text-center info-text">
    <span>
      Das Video besitzt sowohl Ton, als auch Untertitel. Wenn Sie den Ton nutzen
      wollen, schalten Sie den Ton Ihres

      <span v-if="store.osName === 'windows' || store.osName === 'unknown'">
        Computers
      </span>
      <span v-else>Telefons</span>

      ein. Schauen Sie sich in Ruhe das Video so oft an, wie Sie möchten. Es
      dauert ungefähr 5 Minuten.
    </span>
  </div>
</template>

<script setup>
import { dhRegistrationStore } from "@/stores/dh-registration";
const store = dhRegistrationStore();

import { ref } from "vue";

const video = ref(null);
const isFirstPlay = ref(true); // Flag to ensure fullscreen only happens once

const enterFullscreenOnce = () => {
  if (isFirstPlay.value && video.value) {
    isFirstPlay.value = false; // Ensure this only runs once

    if (!document.fullscreenElement) {
      if (video.value.requestFullscreen) {
        video.value.requestFullscreen();
      } else if (video.value.webkitRequestFullscreen) {
        video.value.webkitRequestFullscreen(); // For Safari
      } else if (video.value.msRequestFullscreen) {
        video.value.msRequestFullscreen(); // For IE11
      }
    }
  }
};
</script>

<style scoped>
video {
  width: 100%;
  height: 250px;
}
.info-text {
  font-size: 13px;
}
@media (max-width: 480px) {
  .vert_video {
    width: 100%;
    height: 100%;
  }
}

.vert_video {
  width: 60%;
  height: 60%;
}
</style>
