<template>
  <transition>
    <section v-if="store.step === 7">
      <ConsentGratulationScreen />
    </section>
  </transition>

  <transition>
    <section v-if="store.step === 8">
      <ConsentStartScreen />
    </section>
  </transition>

  <transition>
    <section v-if="store.step === 9">
      <ConsentVideoScreen /><br />
      <NextButton class="mt-2 mb-5" />
    </section>
  </transition>

  <transition>
    <section v-if="store.step === 10">
      <ConsentDocumentScreen />
      <NextButton />
    </section>
  </transition>

  <transition>
    <section v-if="store.step === 11">
      <ConsentDataAppealScreen />
      <NextButton class="mb-5" />
    </section>
  </transition>

  <transition>
    <section v-if="store.step === 12">
      <ConsentDataNameScreen />
    </section>
  </transition>

  <transition>
    <section v-if="store.step === 13">
      <ConsentDataDateSocialSecurityScreen />
    </section>
  </transition>

  <transition>
    <section v-if="store.step === 14">
      <ConsentDataSexScreen />
    </section>
  </transition>

  <transition>
    <section v-if="store.step === 15">
      <ConsentContactPhoneMailScreen />
    </section>
  </transition>

  <transition>
    <section v-if="store.step === 16">
      <ConsentContactAdressScreen />
    </section>
  </transition>

  <transition>
    <section v-if="store.step === 17">
      <ConsentContactConfirmScreen />
    </section>
  </transition>

  <transition>
    <section v-if="store.step === 18">
      <ConsentSignatureScreen />
    </section>
  </transition>
</template>

<script setup>
import ConsentStartScreen from "@/components/screens/consent/ConsentStartScreen.vue";
import ConsentVideoScreen from "@/components/screens/consent/ConsentVideoScreen.vue";
import ConsentDataAppealScreen from "@/components/screens/consent/ConsentDataAppealScreen.vue";
import ConsentDocumentScreen from "@/components/screens/consent/ConsentDocumentScreen.vue";
import ConsentGratulationScreen from "@/components/screens/consent/ConsentGratulationScreen.vue";
import ConsentContactConfirmScreen from "@/components/screens/consent/ConsentContactConfirmScreen.vue";
import ConsentSignatureScreen from "@/components/screens/consent/ConsentSignatureScreen.vue";
import ConsentDataNameScreen from "../screens/consent/ConsentDataNameScreen.vue";
import ConsentDataDateSocialSecurityScreen from "../screens/consent/ConsentDataDateSocialSecurityScreen.vue";
import ConsentDataSexScreen from "../screens/consent/ConsentDataSexScreen.vue";
import ConsentContactPhoneMailScreen from "../screens/consent/ConsentContactPhoneMailScreen.vue";
import ConsentContactAdressScreen from "../screens/consent/ConsentContactAdressScreen.vue";
import NextButton from "@/components/navigation/NextButton.vue";
import { dhRegistrationStore } from "@/stores/dh-registration";
const store = dhRegistrationStore();
</script>

<style scoped></style>
