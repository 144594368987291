<template>
  <transition>
    <section v-if="store.step === 31"><RandomStartScreen /><br /></section>
  </transition>

  <!-- <transition>
    <section v-if="store.step === 32"><RandomCoinScreen /><br /></section>
  </transition> -->

  <transition>
    <section v-if="store.step === 32"><RandomEndScreen /><br /></section>
  </transition>
  <transition>
    <section v-if="store.step === 33">
      <RandomEndInstructionScreen /><br />
    </section>
  </transition>
</template>

<script setup>
import RandomStartScreen from "@/components/screens/random/RandomStartScreen.vue";
import RandomEndScreen from "@/components/screens/random/RandomEndScreen.vue";
import RandomEndInstructionScreen from "@/components/screens/random/RandomEndInstructionScreen.vue";
import { dhRegistrationStore } from "@/stores/dh-registration";
const store = dhRegistrationStore();
</script>

<style scoped></style>
