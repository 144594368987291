<template>
  <h2>Fast geschafft!</h2>
  <div class="text-center">
    Bleiben Sie dran - wir möchten Ihnen nur noch ein paar Fragen stellen, bevor
    Sie an <b>Pulskontrolle</b> teilnehmen können.<br /><br />
    <div class="row my-2">
      Mit diesen wichtigen Fragen zu Ihrer Gesundheit können wir Ihr
      individuelles Risiko abschätzen.
    </div>
  </div>
</template>

<script setup></script>

<style scoped></style>
