<template>
  <div class="row my-5 justify-content-center">
    <div class="col-5">
      <a
        ref="popoverLink"
        tabindex="0"
        data-bs-toggle="popover"
        data-bs-placement="bottom"
        data-bs-offset="0px,50px"
        :data-bs-content="message"
        id="medicusPopover"
        @click="togglePopover"
      >
        <RippleEffectMedicus />
        <!-- <div v-else class="no-ripple">
          <img src="img/medicusQ.png" class="img-responsive fit-image" />
        </div> -->
      </a>
    </div>
    <div class="col-7 align-items-center d-flex text-center">
      Nicht sicher? <br />Fragen Sie Dr. Medicus.
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { Popover } from "bootstrap";
import RippleEffectMedicus from "@/components/utils/RippleEffectMedicus.vue";

// Define the props
const props = defineProps({
  message: {
    type: String,
    required: true,
  },
});

const popoverLink = ref(null);
let popoverInstance = null;

let idleTimer = null; // Timer to track idle state
const idleTime = 8000; // 8 seconds of inactivity to toggle popover
const popoverTriggered = ref(false); // Flag to prevent repeated popover toggling

const togglePopover = (event) => {
  event.stopPropagation();
  if (popoverInstance) {
    popoverInstance.toggle();
    popoverTriggered.value = true;
  }
};

// Hide popover
const hidePopover = () => {
  if (popoverInstance) {
    popoverInstance.hide();
  }
};

// Reset idle timer on user interaction
const resetIdleTimer = () => {
  if (idleTimer) {
    clearTimeout(idleTimer); // Clear the previous timeout
  }
  // Set a new timeout to toggle popover after inactivity, but only once
  idleTimer = setTimeout(() => {
    if (!popoverTriggered.value && popoverInstance) {
      popoverInstance.toggle();
      popoverTriggered.value = true; // Set flag to prevent repeated toggling
    }
  }, idleTime);
};

// Setup event listeners for user interaction
const addInteractionListeners = () => {
  document.addEventListener("click", hidePopover);
  document.addEventListener("mousemove", resetIdleTimer); // Mouse move resets idle timer
  document.addEventListener("keydown", resetIdleTimer); // Key press resets idle timer
};

onMounted(() => {
  if (popoverLink.value) {
    popoverInstance = new Popover(popoverLink.value, {
      trigger: "manual",
    });
  }

  // Start idle timer
  resetIdleTimer();
  addInteractionListeners();
});
</script>

<style scoped>
.fit-image {
  max-height: 100px;
}
</style>
