<template>
  <h2>Ihr Beitrag ist wichtig</h2>
  <h6>
    Sie können aktiv zur Verbesserung der Herzgesundheit in Österreich beitragen
    und davon profitieren. Zur Teilnahme am Programm der Medizinischen
    Universität Innsbruck ist Ihr Einverständnis erforderlich. <br />
    <br />Schauen Sie sich auf der nächsten Seite ein Video an, das Sie über die
    Teilnahme informiert.
  </h6>
  <button
    @click.prevent="store.step++"
    class="btn btn-proceed btn-lg w-100 mt-4 mb-3"
  >
    Zum Video<font-awesome-icon icon="fa-circle-right" class="px-3" />
  </button>
</template>

<script setup>
import { dhRegistrationStore } from "@/stores/dh-registration";
const store = dhRegistrationStore();
</script>

<style scoped></style>
