<template>
  <button
    v-if="(store.step !== 0) & (store.step !== 37)"
    @click.prevent="store.step--"
    class="btn btn-outline-dark px-2"
    :disabled="store.status !== 'in-progress'"
  >
    <font-awesome-icon icon="fa-arrow-left" class="px-1" />
  </button>
</template>

<script setup>
import { dhRegistrationStore } from "@/stores/dh-registration";
const store = dhRegistrationStore();
</script>

<style scoped></style>
