<template>
  <h2>Dürfen wir vorstellen?</h2>
  <div class="row my-4 justify-content-center align-items-center">
    <div class="text-center">
      Unser Dr. Medicus wird Sie durch die Fragen begleiten und Sie
      unterstützen, wenn Sie Hilfe brauchen.
    </div>
  </div>
  <div class="row mt-3 justify-content-center align-items-center">
    <div class="col-5">
      <a
        tabindex="0"
        ref="popoverLink"
        data-bs-toggle="popover"
        data-bs-placement="top"
        data-bs-offset="0px,50px"
        data-bs-content="Ich bin Dr. Medicus und freue mich sehr Sie kennenzulernen. Ich darf Sie durch die Registierung führen und Ihnen helfen, wenn Sie weitere Erklärungen benötigen."
        @click="togglePopover"
      >
        <div class="image-container position-relative d-inline-block">
          <img
            src="img/medicus.png"
            class="img-responsive fit-image"
            alt="Sample Image"
          />
          <div class="click-indicator position-absolute">
            <img
              :src="
                store.osName === 'windows' || store.osName === 'unknown'
                  ? 'img/cursor_click.png'
                  : 'img/finger_click.png'
              "
              class="click-img"
            />

            <!-- Using Font Awesome for the icon -->
          </div>
        </div></a
      >
    </div>
  </div>
  <div class="mt-3 text-center">
    <span v-if="store.osName === 'windows' || store.osName === 'unknown'">
      Klicken
    </span>
    <span v-else>Tippen</span> Sie auf Dr. Medicus für gute Infos und Tipps!
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { Popover } from "bootstrap";
import { dhRegistrationStore } from "@/stores/dh-registration";
const store = dhRegistrationStore();

const popoverLink = ref(null);
let popoverInstance = null;

const togglePopover = (event) => {
  event.stopPropagation();
  if (popoverInstance) {
    popoverInstance.toggle();
  }
};

const hidePopover = () => {
  if (popoverInstance) {
    popoverInstance.hide();
  }
};

onMounted(() => {
  if (popoverLink.value) {
    popoverInstance = new Popover(popoverLink.value, {
      trigger: "manual",
    });
  }
  document.addEventListener("click", hidePopover);
});
</script>

<style scoped>
.fit-image {
  width: 100%;
  object-fit: cover;
}

.click-indicator {
  top: 90%;
  left: 75%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  color: #142b58;
  opacity: 0%;
  animation: fade-in 0.5s forwards, bounce 1s infinite; /* Fades in the icon */
  animation-delay: 2s;
}

.click-img {
  max-width: 3.5rem;
  transform: rotate(-35deg);
}

@keyframes bounce {
  0%,
  100% {
    transform: translate(-50%, -60%);
  }
  50% {
    transform: translate(-30%, -50%);
  }
}
@keyframes fade-in {
  0% {
    opacity: 0; /* Hidden */
  }
  100% {
    opacity: 1; /* Fully visible */
  }
}
</style>
