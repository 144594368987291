<template>
  <div class="row mb-5 justify-content-center">
    <div
      class="col-11 col-sm-9 text-end justify-content-end align-items-center d-flex"
    >
      <img src="img/welcomeBanner.png" class="img-responsive fit-image" />
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <h2 class="mb-5">Herz-lich Willkommen!</h2>
      <h5>
        Starten Sie mit der Registrierung für das digitale Vorsorgeprojekt
        <b>Pulskontrolle</b>.
      </h5>
    </div>
  </div>
</template>

<script setup></script>

<style scoped>
video {
  width: 100%;
  height: 250px;
}
.fit-image {
  max-width: 100%;
}

h6 {
  text-align: start !important;
}
</style>
