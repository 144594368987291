<template>
  <div
    class="modal fade"
    id="completeModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Registrierung abschließen</h5>
        </div>
        <div class="modal-body">
          <h6 class="mb-4">Möchten Sie die Registrierung abschließen?</h6>
          Sie können Ihre angegebenen Information anschließend nicht mehr
          ändern!
        </div>
        <div class="modal-footer py-2">
          <button
            type="button"
            class="btn btn-outline-danger w-100 my-2"
            data-bs-dismiss="modal"
          >
            Nein, ich möchte noch was ändern.
          </button>

          <button
            type="button"
            class="btn btn-proceed w-100 my-2"
            data-bs-dismiss="modal"
            @click="completeRegistration()"
          >
            Ja, ich möchte mich registrieren.
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { dhRegistrationStore } from "@/stores/dh-registration";
const store = dhRegistrationStore();

function completeRegistration() {
  store.subject.cohort = "1"; // this is the "fake" randomisation
  store.status = "completed";
  store.constructFHIRBundle();
  store.step++;
}
</script>

<style scoped></style>
