<template>
  <transition>
    <section v-if="store.step === 0">
      <WelcomeScreen /><br />
      <button
        @click.prevent="testVibrate"
        class="btn btn-proceed btn-lg btn-start w-100 mt-4 mb-3"
      >
        Starten<font-awesome-icon icon="fa-circle-right" class="ps-3" />
      </button></section
  ></transition>

  <transition>
    <section v-if="store.step === 1">
      <WelcomeVideo />
      <NextButton />
    </section>
  </transition>
</template>

<script setup>
import WelcomeScreen from "@/components/screens/start/WelcomeScreen.vue";
import NextButton from "@/components/navigation/NextButton.vue";
import WelcomeVideo from "@/components/screens/start/WelcomeVideo.vue";
import { dhRegistrationStore } from "@/stores/dh-registration";
import { useVibrate } from "@vueuse/core";
const { vibrate, stop, isSupported } = useVibrate({ pattern: [200] });
const store = dhRegistrationStore();

function testVibrate() {
  store.step++;
  vibrate();
}
</script>

<style scoped>
.btn-start {
  height: 75px;
  font-size: x-large;
}
</style>
