<template>
  <h3 class="text-center">
    Konktaktinformation<br />
    <h6>
      (dev only:
      <button @click.prevent="generateMockUp" class="btn btn-success p-2 mx-1">
        <font-awesome-icon icon="user-plus" class="px-0" />
      </button>
      <button @click.prevent="emptyForm" class="btn btn-danger p-2 mx-1">
        <font-awesome-icon icon="user-minus" class="px-0" /></button
      >)
    </h6>
  </h3>
  <form
    class="needs-validation"
    @submit.prevent="validateConsentData"
    novalidate
  >
    <div class="form-floating mb-4">
      <input
        type="email"
        class="form-control"
        :class="[
          emailError === 1 ? 'is-invalid' : '',
          emailError === 2 ? 'is-valid' : '',
        ]"
        id="eMail"
        placeholder="franz.musterfrau@gmail.com"
        autocomplete="email"
        v-model.trim="store.subject.email"
      />
      <label for="eMail">E-Mailadresse</label>
      <div class="invalid-feedback">
        Bitte geben Sie eine gültige E-Mail-Adresse ein.
      </div>
    </div>

    <div class="form-floating mb-4">
      <input
        type="text"
        class="form-control"
        :class="[
          phoneError === 1 ? 'is-invalid' : '',
          phoneError === 2 ? 'is-valid' : '',
        ]"
        id="phoneNumber"
        placeholder="06641234567"
        autocomplete="tel"
        v-model.trim="store.subject.phone"
      />
      <label for="phoneNumber">Telefonnummer</label>
      <div class="invalid-feedback">
        Bitte geben Sie eine gültige Telefonnummer an.
      </div>
    </div>

    <button
      type="submit"
      class="btn btn-proceed btn-lg w-100 my-3"
      :disabled="isFormInvalid"
    >
      Weiter
    </button>
  </form>
</template>

<script setup>
import { dhRegistrationStore } from "@/stores/dh-registration";
import { ref, computed } from "vue";
import { samplePersons } from "@/assets/samplePersons";
const store = dhRegistrationStore();
const emailError = ref(0);
const phoneError = ref(0);

const isFormInvalid = computed(() => {
  return store.subject.phone === "" || store.subject.email === "";
});

function validateEmail() {
  const emailRegex =
    /^([a-z0-9_.-]+(?:\.[a-z0-9_.-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9]))?$/gim;
  if (!emailRegex.test(store.subject.email)) {
    emailError.value = 1;
  } else {
    emailError.value = 2;
  }
}

function validatePhone() {
  store.subject.phone = store.subject.phone.replace(/\s+/g, "");
  const phoneRegex =
    /^((?:(?:\+|00)[1-9]{2,3}|0)(?:[\s\-/()]{0,2}[0-9]{1})(?:[()\s\-/]{0,2}[0-9]){6,13}[0-9])$/gim;
  if (!phoneRegex.test(store.subject.phone)) {
    phoneError.value = 1;
  } else {
    phoneError.value = 2;
  }
}

function validateConsentData() {
  validateEmail();
  validatePhone();
  if (emailError.value === 2 && phoneError.value === 2) {
    store.step++;
  }
}

function generateMockUp() {
  let randomPerson =
    samplePersons[Math.floor(Math.random() * samplePersons.length)];
  store.subject.phone = randomPerson.phone;
}

function emptyForm() {
  store.subject.email = "";
  store.subject.phone = "";
}
</script>

<style scoped>
.consent-text {
  font-size: 12px;
}
</style>
